<template>
  <Modal :showModal="showModal">
    <div
      class="w-full max-w-lg rounded relative bg-white shadow"
      v-click-away="hideModal"
    >
      <div
        class="w-full border-b bg-gray-50 flex justify-between items-start p-3 rounded"
      >
        <div>
          <h1 class="text-sm font-semibold uppercase">
            {{ firstName }} {{ lastName }}
          </h1>
          <p class="text-xs italic">
            {{ organization.name }} - {{ facility.name }}
          </p>
        </div>
        <button
          @click="hideModal"
          class="hover:bg-gray-200 w-6 h-6 rounded flex justify-center items-center -m-1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="ai ai-Cross"
          >
            <path d="M20 20L4 4m16 0L4 20" />
          </svg>
        </button>
      </div>

      <div class="w-full text-xs p-3">
        <div class="w-full flex justify-between items-center my-1">
          <p class="font-semibold">Workflow</p>
          <button
            class="bg-gray-100 p-1 rounded flex justify-between items-center"
          >
            {{ workflow }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="ai ai-ChevronDown ml-1"
            >
              <path d="M4 9l8 8 8-8" />
            </svg>
          </button>
        </div>
        <div class="w-full flex justify-between items-center my-1">
          <p class="font-semibold">Status</p>
          <button
            class="bg-gray-100 p-1 rounded flex justify-between items-center"
          >
            {{ status }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="ai ai-ChevronDown ml-1"
            >
              <path d="M4 9l8 8 8-8" />
            </svg>
          </button>
        </div>
        <div class="w-full flex justify-between items-center my-1">
          <p class="font-semibold">MRN</p>
          <button
            class="bg-gray-100 p-1 rounded flex justify-between items-center"
          >
            11222334-1
          </button>
        </div>
        <div class="w-full flex justify-between items-center my-1">
          <p class="font-semibold">Account Number</p>
          <p class="bg-gray-100 p-1 rounded">24-11223532</p>
        </div>
        <div class="w-full flex justify-between items-center my-1">
          <p class="font-semibold">Birthday</p>
          <p class="bg-gray-100 p-1 rounded">{{ formattedDOB }}</p>
        </div>
        <div class="w-full flex justify-between items-center my-1">
          <p class="font-semibold">Channel</p>
          <p class="bg-gray-100 p-1 rounded">{{ channel }}</p>
        </div>
        <hr class="my-2" />
        <div class="w-full flex justify-between items-center my-1">
          <p class="font-semibold">Follow up</p>
          <p class="bg-yellow-100 p-1 rounded">10/07/2021</p>
        </div>
        <div class="w-full flex justify-between items-center my-1">
          <p class="font-semibold">Advocate</p>
          <p class="bg-gray-100 p-1 rounded">noah@advocatia.io</p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import useBenefitRecord from "../../../composables/useBenefitRecord";
import useFacility from "../../../composables/useFacility";
import Modal from "../../common/ui/Modal.vue";
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ["hideModal"],
  components: {
    Modal,
  },
  setup(_, { emit }) {
    const {
      firstName,
      lastName,
      formattedDOB,
      workflow,
      status,
      channel,
    } = useBenefitRecord();
    const { facility, organization } = useFacility();
    const hideModal = () => {
      emit("hideModal");
    };

    return {
      firstName,
      lastName,
      formattedDOB,
      workflow,
      status,
      channel,
      facility,
      organization,
      hideModal,
    };
  },
};
</script>
