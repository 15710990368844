import { reactive, toRefs } from "vue";

const state = reactive({
  showSidebar: false,
});

const hideSidebar = () => {
  state.showSidebar = false;
};

const displaySidebar = () => {
  state.showSidebar = true;
};

const toggleSidebar = () => {
  state.showSidebar = !state.showSidebar;
};

export default () => {
  return {
    ...toRefs(state),
    hideSidebar,
    displaySidebar,
    toggleSidebar,
  };
};
