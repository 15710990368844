<template>
  <div
    class="w-full bg-white lg:bg-gray-50 fixed left-0 top-0 lg:top-14 z-30 lg:z-10"
    :style="{
      height: `${height}px`,
    }"
    id="sidebar"
  >
    <div class="w-full h-14 flex justify-between items-center lg:hidden p-1">
      <div
        class="w-10 h-10 bg-primary rounded-full flex justify-center items-center"
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 68 78"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 4.27687C0.148933 4.42353 3.22547 5.94485 3.75054 6.24885C4.9844 6.96485 6.17293 7.77685 7.25666 8.54085C8.4036 9.35018 9.51294 10.3102 10.4713 11.1275C11.5833 12.0782 12.2057 12.9435 13.2531 14.1422C15.9244 17.2008 18.0167 21.9995 18.2791 26.4568C18.4077 28.6328 18.0635 29.7382 17.9229 31.6195C20.1615 31.9289 21.9973 32.0115 24.1495 32.6022C24.9943 32.8342 26.0995 33.3222 26.7801 33.4769C27.7011 33.6862 28.2869 32.4729 28.2713 31.4355C24.1104 29.5595 23.8615 30.1768 20.7339 29.1755C20.9167 23.2048 19.2615 18.3409 16.6776 14.2329C14.6833 11.0622 12.5681 9.28618 9.71773 6.96085C9.05053 6.41552 7.6 5.67286 6.84213 5.06886C12.2344 4.88886 17.0943 5.67951 21.1615 7.99151C23.9088 9.55418 26.1765 12.0928 27.276 15.0568C27.9624 16.9115 27.7339 16.9515 28.0077 17.9995C28.3172 19.1809 27.7088 17.1862 28.0995 18.2795C28.3208 18.9009 30.6129 19.1035 32.1875 19.6408C33.4911 20.0875 34.5713 20.5515 35.7536 21.1622C38.1124 22.3782 40.2063 23.9969 41.7167 25.5689C44.7457 28.7222 47.5869 33.3022 48.8353 39.1555C50.6247 39.9635 52.3233 39.3995 55.7353 41.9489C59.6087 44.8435 61.4647 49.9782 62.2087 55.0022C62.6993 58.3315 62.458 60.8822 62.502 64.1675C63.9593 62.2982 66.1367 54.8755 66.718 51.9062C68.5167 42.7328 67.2687 32.4835 63.09 24.2248C61.4767 21.0382 60.4087 19.2582 58.3673 16.4715C57.806 15.7062 57.0873 14.9128 56.582 14.1888C57.3807 13.6315 62.23 8.40619 63.4687 7.20086C64.8113 5.89286 64.5447 5.93819 63.7847 4.06752C61.9953 3.78619 61.7633 4.47685 60.9047 5.33951C59.6073 6.64085 54.806 11.7075 53.982 12.0248C52.8233 10.6995 51.006 9.17152 49.3647 7.90086C48.818 7.47819 48.6393 7.42085 48.09 7.06218C37.8859 0.394185 26.1563 -1.11649 13.9572 0.724847C10.8484 1.19418 2.10107 2.95153 0 4.27687"
            fill="#fff"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M38.9552 27.4235L13.4953 52.6475C11.3443 54.7942 9.25107 56.7609 7.10467 58.9089C4.12547 61.8929 -0.722937 65.5502 1.83333 71.2609C2.42186 72.5755 3.68279 73.9675 4.81293 74.6049C7.85719 76.3209 12.3473 75.8342 14.5755 73.7649C16.3599 72.1062 14.39 69.9529 13.1905 70.9689C12.9115 71.2062 12.8801 71.4769 12.4588 71.8155C10.1072 73.7049 5.81613 72.3169 4.7948 70.0569C3.9448 68.1729 4.74213 66.3422 5.6932 65.2329L11.9801 58.8649C14.102 56.7329 16.2104 54.6729 18.2609 52.4915C20.2937 50.3315 22.4973 48.3675 24.6391 46.2195C29.8359 41.0075 35.1677 35.3929 40.3629 30.3115C40.8583 29.8262 41.5848 29.2875 41.1432 28.0849C40.8921 27.4022 39.8421 27.0502 38.9552 27.4235"
            fill="#fff"
          />
        </svg>
      </div>
      <button
        class="flex justify-center items-center rounded hover:bg-gray-100 w-8 h-8 ml-1 lg:hidden"
        @click="hideSidebar"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          display="block"
          id="Cross"
        >
          <path d="M20 20L4 4m16 0L4 20" />
        </svg>
      </button>
    </div>
    <div
      class="w-full h-full overflow-y-scroll font-medium sidebar-scroll"
      :style="{ maxHeight: `calc(${height}px - 3.5rem)` }"
    >
      <div class="px-2 pb-2">
        <div class="w-full flex justify-between items-center mt-2">
          <button
            class="text-blue-500 hover:underline flex items-center"
            @click="displayBenefitRecordModal"
          >
            <h2 class="font-semibold">{{ firstName }} {{ lastName }}</h2>
          </button>
        </div>
        <StatusSelect />
        <hr class="mt-2" />
      </div>

      <SidebarButton :to="{ name: 'dashboard' }">Dashboard</SidebarButton>
      <SidebarButton
        :to="{ name: 'details' }"
        :subMenus="[
          { routeName: { name: 'details' }, name: 'About you' },
          { routeName: { name: 'contact' }, name: 'Contact' },
          { routeName: { name: 'address' }, name: 'Address' },
        ]"
        >Details</SidebarButton
      >
      <SidebarButton :to="{ name: 'household' }">Household</SidebarButton>
      <SidebarButton
        :to="{ name: 'interview' }"
        :subMenus="residentInterviewSubMenu"
        >Interview</SidebarButton
      >
      <SidebarButton :to="{ name: 'income' }">Income</SidebarButton>
      <SidebarButton :to="{ name: 'assets' }">Assets</SidebarButton>
      <SidebarButton :to="{ name: 'expenses' }">Expenses</SidebarButton>
      <SidebarButton
        :to="{ name: 'forms' }"
        :subMenus="[{ routeName: { name: 'forms' }, name: 'Review / Sign' }]"
        >Forms</SidebarButton
      >
      <hr class="my-2 mx-2" />
      <SidebarButton :to="{ name: 'my-screenings' }"
        >My Screenings</SidebarButton
      >
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import useBenefitRecord from "../../../composables/useBenefitRecord";
import useSidebar from "../../../composables/useSidebar";
import useWindowSize from "../../../composables/useWindowSize";
import useFacility from "../../../composables/useFacility";
import SidebarButton from "../ui/SidebarButton";
import StatusSelect from "../../home/ui/StatusSelect.vue";
export default {
  components: {
    SidebarButton,
    StatusSelect,
  },
  emits: ["displayBenefitRecordModal"],
  setup(_, { emit }) {
    const { height } = useWindowSize();
    const { hideSidebar } = useSidebar();
    const { residents, firstName, lastName, status } = useBenefitRecord();
    const { organization, facility } = useFacility();
    const residentInterviewSubMenu = computed(() => {
      const subMenu = [
        { routeName: { name: "interview" }, name: "Household Interview" },
      ];
      residents.value.forEach((resident, index) => {
        subMenu.push({
          routeName: { name: "resident-interview", params: { id: index + 1 } },
          name: resident.label,
        });
      });

      return subMenu;
    });

    const displayBenefitRecordModal = () => {
      hideSidebar();
      emit("displayBenefitRecordModal");
    };

    return {
      hideSidebar,
      height,
      residentInterviewSubMenu,
      firstName,
      lastName,
      status,
      organization,
      facility,
      displayBenefitRecordModal,
    };
  },
};
</script>

<style scoped>
#sidebar {
  border-right: 1px solid theme("colors.gray.200");
  max-width: 18rem;
}

.sidebar-scroll {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.sidebar-scroll::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
</style>
