<template>
  <div class="w-full relative flex justify-center">
    <button
      @click="displaySearchbar"
      class="bg-gray-200 rounded text-xs py-2 w-full max-w-64 text-gray-500 flex justify-center items-center cursor-text"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="ai ai-Search mr-2"
      >
        <path
          d="M21 21l-4.486-4.494M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0z"
        /></svg
      >Search Record
    </button>
    <div
      v-if="showSearchbar"
      v-click-away="hideSearchbar"
      class="absolute top-0 text-xs z-10 w-screen max-w-4xl px-1"
    >
      <div class="w-full bg-white shadow-md rounded border">
        <div class="w-full border-b p-2 flex justify-start items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="ai ai-Search mr-2 text-gray-500 flex-shrink-0"
          >
            <path
              d="M21 21l-4.486-4.494M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0z"
            />
          </svg>
          <button
            class="bg-gray-100 mx-1 px-2 py-1 rounded hover:bg-gray-200 flex items-center"
            v-show="filter"
            @click="clearFilter"
          >
            {{ filter }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="ai ai-Cross ml-1"
            >
              <path d="M20 20L4 4m16 0L4 20" />
            </svg>
          </button>
          <input
            type="text"
            v-model="query"
            id="search-input"
            placeholder="Search for a record"
            class="w-full py-1"
          />
          <router-link
            class="hover:underline text-blue-500 flex-shrink-0"
            :to="{ name: 'search' }"
            >More results</router-link
          >
        </div>
        <div
          v-show="query !== ''"
          class="w-full flex justify-between items-center p-2"
        >
          <p>
            {{ searchText }} <span class="font-medium">{{ query }}</span
            >...
          </p>
        </div>
        <div
          v-show="!filter"
          class="flex flex-wrap justify-start items-center px-2 py-1"
        >
          <p class="mr-1">Search for:</p>
          <button
            class="bg-gray-100 m-1 px-2 py-1 rounded hover:bg-gray-200 capitalize"
            v-for="filter in filters"
            @click="setFilter(filter)"
            :key="filter"
          >
            {{ filter }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
export default {
  setup() {
    const filters = [
      "Facilities",
      "Channels",
      "Workflows",
      "Statuses",
      "Eligibilities",
      "Advocates",
    ];
    const focusSearchInput = () => {
      window.setTimeout(function () {
        document.getElementById("search-input").focus();
      }, 0);
    };
    const state = reactive({
      showSearchbar: false,
      query: "",
      filter: null,
    });
    const searchText = computed(() => {
      if (state.filter !== null) {
        return `Searching for records with ${state.filter} named`;
      } else return `Searching for records named`;
    });
    const displaySearchbar = () => {
      state.showSearchbar = true;
      focusSearchInput();
    };
    const hideSearchbar = () => {
      state.showSearchbar = false;
    };
    const setFilter = (filter) => {
      state.filter = filter;
      focusSearchInput();
    };
    const clearFilter = () => {
      state.filter = null;
      focusSearchInput();
    };

    return {
      filters,
      ...toRefs(state),
      searchText,
      displaySearchbar,
      hideSearchbar,
      setFilter,
      clearFilter,
    };
  },
};
</script>

<style></style>
