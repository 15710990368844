export const english = {
  welcomeBenefitsProgramScreener: "Welcome to the Benefits Program Screener",
  progress: "Progress",
  getStarted: "Let's get started!",
  next: "Next",
  back: "Back",
  basedOnYourAnswersYouQualifyFor:
    "Below are the benefits you may be qualified for:",
  uniteUsCongrats:
    "Congratulations on finding out what programs you might be eligible for! Click the button below to return to the Unite Louisiana site and request assistance with benefits enrollment and other services that you may need.",
  continueSignUp: "Continue Sign-Up",
  needAssistance:
    "Need assistance finding benefits? We’re here to help, for FREE.",
  quickEasyFree: "It's easy, It's quick, It's FREE",
  question: "Question",
  oneInFiveQualify: "1/5 Americans qualify for Medicaid.",
  medicaidCoversBroadRange:
    "Medicaid covers a broad range of health and long-term care services.",
  yourResults: "Your Results",
  friendlyAdvocatiaRepContact:
    "Please provide your contact information and a helpful Representative will assist you for free. Your information will not be shared.",
  startEnrollment: "Start Enrollment",
  programs: "Programs",
  ratherTalkToAdvisor: "Rather talk to one of our enrollment advisors?",
  weAreHereToHelp: "We are here to help.",
  callUsAt: "Call us at",
  text: "text",
  to: "to",
  or: "or",
  visitUsHere: "visit us here.",
  ifYouMake30Or100:
    "If you make $30k or $110K YOU can get help with coverage. We can show you how.",
  submit: "Submit",
  startProcess: "Start the Process Myself",
  wantToStart:
    "Want to start the process yourself? Enter your birthday to get started.",
  requestAssistance: "Request Assistance",
  selectAnOption: "Select an option",
  enterNumber: "Enter a number",
  termsConditions: "Terms and Conditions",
  firstName: "Enter first name",
  lastName: "Enter last name",
  enterEmail: "Enter email",
  enterPhone: "Enter phone",
  enterTimeOfDay: "Enter time of day and/or days of week",
  acknowledgeTerms: "I Acknowledge the",
};
