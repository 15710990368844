import { reactive } from "vue";
import { languages } from "../common/languages/index";

const state = reactive({});
Object.assign(state, languages.en);

const setLanguage = (languageCode) => {
  Object.assign(state, languages[languageCode]);
};

export default () => {
  return {
    language: state,
    setLanguage,
  };
};
