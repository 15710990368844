import axios from "axios";
import { getAPIUrl, getFacilityId } from "../utils/functions";

const apiURL = getAPIUrl();
const facilityId = getFacilityId();
const apiClient = axios.create({
  baseURL: apiURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key": "28B3D256-70AD-4798-9117-E094EAD191E6",
  },
});

export default {
  getEligibility(payload) {
    return apiClient.post("/services/eligibility", payload);
  },
  getBenefitRecord(benefitRecordId) {
    return apiClient.get(`/api/screen/${benefitRecordId}`);
  },
  postBenefitRecord(payload) {
    return apiClient.post("services/quickscreen/benefitRecord", payload);
  },
  getQuickscreen() {
    return apiClient.get(`/services/quickscreen/${facilityId}`);
  },
  postQuickscreen(payload) {
    return apiClient.post("/services/quickscreen", payload);
  },
  getAdvocates() {
    return apiClient.get(`/services/advocate/${facilityId}`);
  },
  setAdvocate(payload) {
    return apiClient.post("/services/advocate", payload);
  },
  getFacility() {
    return apiClient.get(`/services/facility/${facilityId}`);
  },
  verifyDOB(payload) {
    return apiClient.post(`/services/verify-dob`, payload);
  },
};
