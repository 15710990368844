<template>
  <div class="w-full text-xs relative" v-click-away="hideStatuses">
    <div class="w-full flex items-center rounded" @click="displayStatuses">
      <p
        class="w-full bg-transparent text-blue-500 cursor-pointer hover:underline"
      >
        {{ status }}
      </p>
    </div>
    <div
      class="w-full bg-white shadow-md absolute max-h-64 mt-1 overflow-y-scroll rounded status-scroll py-1"
      v-if="showStatuses"
    >
      <input
        type="text"
        v-model="filter"
        v-show="showStatuses"
        placeholder="Type to filter"
        class="w-full bg-transparent p-2"
        id="status-filter-input"
      />
      <hr />
      <button
        v-for="statusOption in filteredStatuses"
        :key="statusOption"
        class="w-full text-left p-2"
        :class="statusOption === status ? 'bg-gray-200' : ' hover:bg-gray-100'"
        @click="updateStatus(statusOption)"
      >
        {{ statusOption }}
      </button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import useBenefitRecord from "../../../composables/useBenefitRecord";
import useFacility from "../../../composables/useFacility";
import { computed } from "@vue/runtime-core";
export default {
  setup() {
    const { status, setStatus, saveBenefitRecord } = useBenefitRecord();
    const { facility } = useFacility();
    const state = reactive({
      filter: "",
      showStatuses: false,
    });

    const focusFilterStatusInput = () => {
      window.setTimeout(function () {
        document.getElementById("status-filter-input").focus();
      }, 0);
    };

    const displayStatuses = () => {
      state.showStatuses = true;
      state.filter = "";
      focusFilterStatusInput();
    };

    const hideStatuses = () => {
      state.showStatuses = false;
    };
    const filteredStatuses = computed(() => {
      if (!facility.value.statuses) return [];
      if (state.filter === status.value)
        return Object.values(facility.value.statuses);
      return Object.values(facility.value.statuses).filter((status) =>
        status.toUpperCase().includes(state.filter.toUpperCase())
      );
    });

    const updateStatus = async (status) => {
      setStatus(status);
      hideStatuses();
      await saveBenefitRecord();
    };

    return {
      filteredStatuses,
      status,
      ...toRefs(state),
      displayStatuses,
      hideStatuses,
      updateStatus,
    };
  },
};
</script>

<style scoped>
.status-scroll {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.status-scroll::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

input[type="text"] {
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input::-webkit-input-placeholder {
  font-weight: 300;
  color: theme("colors.gray.500");
}
input::-moz-placeholder {
  font-weight: 300;
  color: theme("colors.gray.500");
}
input::-ms-input-placeholder {
  font-weight: 300;
  color: theme("colors.gray.500");
}
</style>
